<template>
  <div>
    <div class="training-language-course mx-auto position-relative mt-3">
      <div class="d-flex justify-content-between align-items-center mb-2 gap-4">
        <CButton color="primary" v-if="courseDetail.filter_names">
          {{ courseDetail.filter_names }}
        </CButton>
        <CButton color="primary" to="/tfw/course">Back</CButton>
      </div>
      <img
        v-if="courseDetail.img"
        ref="myImage"
        :src="courseDetail.img"
        class="img-fluid"
        style="width: 100%; max-width: 1154px"
        @click="onImageClick"
      />
      <!-- <CButton v-if="courseDetail.img" class="btn-login" to="/tfw/login">Login / Register</CButton> -->
    </div>
    <div class="tfw-course">
      <Modal
        :modalTitle="confirmModal.modalTitle"
        :modalColor="confirmModal.modalColor"
        :modalContent="confirmModal.modalContent"
        :isShowPopup="confirmModal.isShowPopup"
        :buttons="confirmModal.buttons"
        :modalCallBack="confirmModalCallBack"
        :closeOnBackdrop="false"
        v-if="confirmModal.isShowPopup"
      />
    </div>
  </div>
</template>
<script>
import { CONSTANT } from "@/helpers/helper";
import Modal from "@/components/reusable/Modal";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      courseDetail: null,
      confirmModal: {
        modalColor: "primary",
        modalTitle: "Confirmation",
        modalContent: "Do you want to Register Now?",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
    };
  },
  computed: {
    isSLCCourse() {
      return this.courseDetail?.course_id === 2;
    },
  },
  methods: {
    confirmModalCallBack(action) {
      if (action === "Confirm") {
        this.$router.push("/web/login");
      }
      this.confirmModal.isShowPopup = false;
    },
    updateProfilePage() {
      const { id } = this.$route.params;
      const courses = CONSTANT.courses;
      this.courseDetail = courses.find((v) => v.course_id == id);
    },
    onImageClick(event) {
      const image = this.$refs.myImage;
      const boundingRect = image.getBoundingClientRect();
      const xPosition = event.clientX - boundingRect.left;
      const yPosition = event.clientY - boundingRect.top;

      const isClicked = this.courseDetail.btnPosition.some((v) => {
        const { x, y } = v;
        const xCond = x.start <= xPosition && x.end >= xPosition;
        const yCond = y.start <= yPosition && y.end >= yPosition;
        return xCond && yCond;
      });
      if (isClicked) {
        this.confirmModal.isShowPopup = true;
      }
    },
  },
  created() {
    this.updateProfilePage();
  },
  watch: {
    "$route.params": function () {
      this.updateProfilePage();
    },
  },
};
</script>
<style lang="scss" scoped>
.training-language-course {
  width: 1150px;
}
.btn-login {
  position: absolute;
  right: 0;
  background-color: #fff;
  margin-right: 18px;
  padding-bottom: 0px;
  width: 200px;
  color: #1d3787;
}
</style>
